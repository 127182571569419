import {
  MatchCompetitorFragment,
  MatchContestFragment,
  PlayerQueueFragment,
  QueueStatus,
  useAcceptChallengeOpponentMutation,
  useCancelInviteToChallengeMutation,
  useRejectChallengeOpponentMutation,
  UserMatchmakingStatus,
} from 'graphpl/core';
import React, { useState } from 'react';
import { addCommasToNumber } from 'components/util/add-commas-to-number';
import { Avatar } from 'components/atoms/new-avatar';
import { AvailableIcons, Iconography } from 'components/styles';
import { useTheme } from 'styled-components';
import { LoadingSpinner } from 'components/atoms';
import { useToast } from 'components/util/toast-context';
import { navigate } from 'helpers';
import { useGlobal } from 'components/util/global-context';
import { useUser } from 'components/util/user-context';
import {
  AcceptButton,
  ButtonWrapper,
  ContentWrapper,
  JoinedDetailsText,
  PlPlusUsernameText,
  RejectButton,
  RightTextWrapper,
  RowWrapper,
  TextWrapper,
  UsernameText,
} from './joined-user-row.styles';

type JoinedUserRowProps = {
  matchData: MatchContestFragment;
  opponent: PlayerQueueFragment;
  storybookRejectLoading?: boolean;
  storybookAcceptLoading?: boolean;
  storybookDisabled?: boolean;
};

export const JoinedUserRow = ({
  matchData,
  opponent,
  storybookRejectLoading,
  storybookAcceptLoading,
  storybookDisabled,
}: JoinedUserRowProps) => {
  const { setUserRaw } = useUser();
  const [localRejected, setLocalRejected] = useState(false);
  const { displayToast } = useToast();
  const { games } = useGlobal();
  const theme = useTheme();

  const [
    rejectOpponentFromContest,
    { loading: rejectLoading, called: rejectCalled, error: rejectError },
  ] = useRejectChallengeOpponentMutation({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: {
      challengeId: matchData.id || '',
      userId: opponent?.user?.id || '',
    },
    onCompleted: () => {
      setLocalRejected(true);
    },
    onError: (error) => {
      displayToast({
        toastDisplayed: true,
        type: 'error',
        heading: 'Failed to reject opponent',
        subtext: error.message,
      });
    },
  });

  const [
    acceptOpponentFromContest,
    { loading: acceptLoading, called: acceptCalled, error: acceptError },
  ] = useAcceptChallengeOpponentMutation({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: {
      challengeId: matchData.id || '',
      userId: opponent?.user?.id || '',
    },
    onCompleted: () => {
      setUserRaw((prev) => {
        if (!prev) return prev;
        if (prev?.status?.matchmakingStatus === UserMatchmakingStatus.INACTIVE)
          return prev;
        return {
          ...prev,
          status: {
            ...prev.status,
            matchmakingStatus: UserMatchmakingStatus.INACTIVE,
          },
        };
      });
      navigate(`/matches/${matchData.id}`);
    },
    onError: (error) => {
      displayToast({
        toastDisplayed: true,
        type: 'error',
        heading: 'Failed to accept opponent',
        subtext: error.message,
      });
    },
  });

  const rejected = opponent.status === QueueStatus.REJECTED || localRejected;
  const cancelled = opponent.status === QueueStatus.CANCELLED;

  const game = games.find((g) => g.id === matchData.game?.id);
  const format = game?.gameFormats?.find(
    (f) => f?.key === opponent?.proposedFormat,
  );

  const inputDisabled =
    storybookDisabled ||
    storybookRejectLoading ||
    storybookAcceptLoading ||
    rejectLoading ||
    (rejectCalled && !rejectError) ||
    acceptLoading ||
    (acceptCalled && !acceptError);

  return (
    <RowWrapper disabled={rejected || cancelled}>
      <Avatar
        size="large"
        user={opponent.user as MatchCompetitorFragment}
        plr={{
          type: 'tile',
          context: 'challenge',
          opponentUserId: opponent?.user?.id,
          opponentRatings: opponent?.user?.plrRatings,
          gameSeriesId: matchData.game?.gameSeriesId,
        }}
      />
      <ContentWrapper>
        <TextWrapper>
          {opponent?.user?.usernameFlairs?.hasPlPlus ? (
            <PlPlusUsernameText>{opponent?.user?.username}</PlPlusUsernameText>
          ) : (
            <UsernameText>{opponent?.user?.username}</UsernameText>
          )}
          <JoinedDetailsText>
            ${addCommasToNumber(opponent?.proposedAmount || 0)} |{' '}
            {format?.name || opponent?.proposedFormat}
          </JoinedDetailsText>
        </TextWrapper>

        <ButtonWrapper>
          <RejectButton
            disabled={inputDisabled || rejected || cancelled}
            onClick={() => rejectOpponentFromContest()}
            data-testid="reject-button"
          >
            {storybookRejectLoading || rejectLoading ? (
              <LoadingSpinner
                color={theme.new.theme === 'light' ? 'blue' : 'white'}
              />
            ) : (
              <Iconography
                name={AvailableIcons.CROSS_CIRCLE}
                color={theme.new.error.background}
              />
            )}
          </RejectButton>
          <AcceptButton
            disabled={inputDisabled || rejected || cancelled}
            onClick={() => acceptOpponentFromContest()}
            data-testid="accept-button"
          >
            {storybookAcceptLoading || acceptLoading ? (
              <LoadingSpinner
                color={theme.new.theme === 'light' ? 'blue' : 'white'}
              />
            ) : (
              <Iconography
                name={AvailableIcons.TICK_CIRCLE_FILLED}
                color={theme.new.information.background}
              />
            )}
          </AcceptButton>
        </ButtonWrapper>
      </ContentWrapper>
    </RowWrapper>
  );
};

type PendingUserRowProps = {
  matchData: MatchContestFragment;
  opponent: MatchCompetitorFragment;
  storybookLoading?: boolean;
  storybookDisabled?: boolean;
};

export const PendingUserRow = ({
  matchData,
  opponent,
  storybookLoading,
  storybookDisabled,
}: PendingUserRowProps) => {
  const theme = useTheme();
  const { displayToast } = useToast();
  const loadingSpinnerColor = theme.new.theme === 'light' ? 'blue' : 'white';
  const [displayed, setDisplayed] = useState(true);
  const [
    cancelInvite,
    { loading, called, error },
  ] = useCancelInviteToChallengeMutation({
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setDisplayed(false);
      displayToast({
        toastDisplayed: true,
        type: 'success',
        heading: 'Successfully cancelled invite',
      });
    },
    onError: (err) => {
      displayToast({
        toastDisplayed: true,
        type: 'error',
        heading: 'Failed to cancel invite',
        subtext: err.message,
      });
    },
  });

  const handleCancelInvite = () => {
    if (!matchData.id) return;
    if (!opponent.id) return;

    cancelInvite({
      variables: {
        challengeId: matchData.id,
        userId: opponent.id,
      },
    });
  };

  const loadingCancel = storybookLoading || loading;

  const disableCancel = storybookDisabled || (!error && called);
  if (!displayed) return null;
  return (
    <RowWrapper disabled={false}>
      <Avatar
        size="large"
        user={opponent}
        plr={{
          type: 'tile',
          context: 'challenge',
          opponentUserId: opponent.id,
          opponentRatings: opponent.plrRatings,
          gameSeriesId: matchData.game?.gameSeriesId,
        }}
      />
      <ContentWrapper>
        <TextWrapper>
          {opponent.usernameFlairs?.hasPlPlus ? (
            <PlPlusUsernameText>{opponent.username}</PlPlusUsernameText>
          ) : (
            <UsernameText>{opponent.username}</UsernameText>
          )}
          <JoinedDetailsText>Invite sent</JoinedDetailsText>
        </TextWrapper>
        <RightTextWrapper disabled={disableCancel} onClick={handleCancelInvite}>
          {loadingCancel ? (
            <LoadingSpinner size={'22px'} color={loadingSpinnerColor} />
          ) : (
            <Iconography
              name={AvailableIcons.CANCEL_SEND}
              color={theme.new.error.background}
            />
          )}
        </RightTextWrapper>
      </ContentWrapper>
    </RowWrapper>
  );
};
