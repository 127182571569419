import { FeaturedStreamFragment, GameSeries } from 'graphpl/cms';
import { isWithinInterval } from 'date-fns';

const doesHaveActiveSteam = (
  stream: FeaturedStreamFragment,
  lobbyGameSeries: string,
): FeaturedStreamFragment | undefined => {
  if (!stream.enabled) return;

  const activeStreams = stream.streamTimes.filter(
    ({ startTime, endTime, gameSeries }) => {
      const active = isWithinInterval(Date.now(), {
        start: new Date(startTime),
        end: new Date(endTime),
      });
      const included = gameSeries.includes(lobbyGameSeries as GameSeries);
      return active && included;
    },
  );

  if (activeStreams.length === 0) return;

  return {
    ...stream,
    streamTimes: activeStreams,
  };
};

export const featuredStreamFunc = (
  allFeaturedStreams: FeaturedStreamFragment[],
) => (lobbyGameSeries: string): FeaturedStreamFragment | undefined => {
  if (!allFeaturedStreams) return;
  if (allFeaturedStreams.length === 0) return;

  return allFeaturedStreams.reduce<FeaturedStreamFragment | undefined>(
    (acc, stream) => {
      if (acc) return acc;
      return doesHaveActiveSteam(stream, lobbyGameSeries);
    },
    undefined,
  );
};
